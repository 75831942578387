jQuery(document).ready(function($){
	// initMobileNav();
	initSlickCarousel();
	initSameHeight();
});

jQuery(window).on('load', function(){
});

// align blocks height
function initSameHeight() {
	jQuery('.posts-block__container').sameHeight({
		elements: '.blog-post__header',
		useMinHeight: true,
		flexible: true,
		multiLine: true
	});
}

// slick init
function initSlickCarousel() {
	jQuery('.hero-slider').slick({
		rows: 0,
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		fade: true,
		arrows: false,
		appendDots: $('.slick-dots-container')
	});
}

// mobile menu init
// function initMobileNav() {
// 	jQuery('body').mobileNav({
// 		menuActiveClass: 'nav--active',
// 		menuOpener: '.nav__toggle',
// 		hideOnClickOutside: true,
// 		menuDrop: '.nav__container'
// 	});
// }